<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="addUser">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <croppa v-model="myCroppa" ref="myCroppa" accept="image/*" placeholder="Preview Image"
                                :width="200" :height="200" :quality="2" :prevent-white-space="true"
                                :show-remove-button="true" @file-choose="displayRefresh" />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Full name</label>
                                    <input type="text" class="form-control" v-model="user.fullname" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="email" class="form-control" v-model="user.email" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Telephone</label>
                                    <input type="tel" class="form-control" v-model="user.telephone" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Date of Birth</label>
                                    <datepicker v-model="user.dob" :bootstrap-styling="true" :typeable="true" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Gender</label>
                                    <select class="form-control" v-model="user.gender" required>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Role</label>
                                    <select class="form-control" v-model="user.role" required>
                                        <option>Administrator</option>
                                        <option>Finance</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input type="password" class="form-control" v-model="user.npassword">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input type="password" class="form-control" v-model="user.rpassword">
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-success" :disabled="isProcessing">
                            <loader v-if="isProcessing" />
                            <span v-else>Save User</span>
                        </button>
                    </div>

                </div>

                <!-- <pre>{{ user }}</pre> -->

            </form>
        </div>
    </div>
</template>


<script>
import Datepicker from 'vuejs-datepicker'
import firebase from "firebase/app"
import "firebase/functions"
// import { v4 as uuidv4 } from "uuid"
import dayjs from 'dayjs';

export default {
    data() {
        return {
            myCroppa: {},
            isSelected: false,
            isProcessing: false,
            user: {
                fullname: "",
                email: "",
                telephone: "",
                dob: "",
                gender: "",
                role: "",
                npassword: "",
                rpassword: "",
                photo: "",
            },
        }
    },
    components: {
        Datepicker,
        "loader": () => import("@/components/Loaders/Button.vue"),
    },
    methods: {

        displayRefresh() {
            this.isSelected = true
        },

        async addUser() {

            let { npassword, rpassword, email, dob, gender, fullname, telephone, role, photo } = this.user;

            if (npassword !== rpassword) {
                this.$toast.error("Passwords do not match")
                return
            }

            try {
                this.isProcessing = true;
                const { user } = await this.$auth.createUserWithEmailAndPassword(email, npassword);
                const { uid } = user;
                const photoData = this.$refs.myCroppa.generateDataUrl()
                if (photoData) {
                    const filename = 'users/' + uid + '.jpg';
                    const metadata = { contentType: 'image/jpeg' };
                    const snapshot = await this.$st.ref(filename).putString(photoData, 'data_url', metadata);
                    photo = await snapshot.ref.getDownloadURL();
                }

                dob = dob ? dayjs(dob).toDate() : null

                // compose the users data
                const payload = {
                    uid,
                    createdAt: dayjs().toDate(),
                    createdBy: {
                        email: null,
                        uid: null,
                        name: null,
                        picture: null,
                    },
                    dob,
                    email,
                    gender,
                    fullname,
                    photo,
                    role,
                    telephone,
                }

                // add the user to the users collection
                await this.$db.collection("users").doc(uid).set(payload)

                // increment the users count
                await this.$db.collection("statistics").doc("totals").update({
                    users: firebase.firestore.FieldValue.increment(1)
                });

                this.$router.push('/users');

            } catch (error) {
                const { message } = error;
                this.$toast.error(message);
                return;
            } finally {
                this.isProcessing = false;
            }
        },




        async save(user) {
            const createUser = this.$fn.httpsCallable("createUser")
            const response = await createUser(user)
            this.$refs.myCroppa.refresh()
            this.isSelected = false
            this.user = {
                fullname: "",
                email: "",
                telephone: "",
                dob: "",
                gender: "",
                role: "",
                npassword: "",
                rpassword: "",
                photo: "",
            }
            this.$toast.success(response.data.message)
        },

        async savePhoto() {
            const file = this.$refs.myCroppa.generateDataUrl()
            const uploadPhoto = firebase.functions().httpsCallable("uploadPhoto")
            const response = await uploadPhoto({ photo: file })
            console.log(response)
        },

    }
}
</script>


<style scoped>
.input-group .form-control {
    padding-left: 0;
    padding-right: 0;
}
</style>